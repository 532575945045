<template>
    <div id="app">
        <div class="head">

        </div>
        <div class="search-content">
            <search-bar style="margin-top: 80px;"/>
        </div>
        <div class="main-content">
            <Item v-for="(item, index) in navigation.navigation" :key="index" :val="item"/>
            <!--            <Item/>-->
            <!--            <Item/>-->
            <!--            <Item/>-->
            <!--            {{ result }}-->
        </div>
        <div class="tail">
            <div style="margin-top: 100px">
                <p>
                    {{ yiju.content + "——" + yiju.from }}
                </p>
                <p>

                </p>
                <br>
                <p>
                    <a class="bottom" href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2021006202号-2</a>
                </p>
                <br>
                <p style="font-size: 10px">
                    参考自<a class="bottom" href="https://0x3.com" target="_blank">0x3</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import SearchBar from "@/components/SearchBar";
import Item from "@/components/Item";
import navigation from "./navigation.json"

export default {
    name: 'App',
    data() {
        return {
            navigation,
            yiju: {"content": "一句加载中...", "from": "Integer.top"}
        }
    },
    components: {
        SearchBar,
        Item
    },
    created() {
        this.axios.get("https://up.api.daidr.me/apis/hitokoto.json")
            .then(res => this.yiju = res.data);
    }
}
</script>

<style>
.main-content {
    /*height: 550px;*/
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #f7f7f7;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /*margin-top: 60px;*/
    background-color: white;
    /*margin: 30px;*/
    width: 1200px;
    margin: 30px auto;
    border-radius: 30px;
}

.head {
    /*background-color: black;*/
    /*width: 300px;*/
    /*height: 600px;*/
    height: 100px;
}

.search-content {
    height: 150px;
    /*background-color: #d94848;*/
}

.tail {
    height: 200px;
}
.bottom{
    text-decoration: none;
    color: black;
    font-weight: bold;
}
</style>
