<template>
    <div>
        <div class="select">
            <span ref="google" class="item" style="margin-left: 0" @click="changeSearch(urls[0], 0)">Google</span>
            <span ref="bing" class="item" @click="changeSearch(urls[1], 1)">Bing</span>
            <span ref="baidu" class="item" @click="changeSearch(urls[2], 2)">百度</span>
            <span ref="zhihu" class="item" @click="changeSearch(urls[3], 3)">知乎</span>
            <span ref="wikipedia" class="item" @click="changeSearch(urls[4], 4)">Wikipedia</span>
            <span ref="lucky" class="item" @click="changeSearch(null, 5)">Lucky</span>
        </div>
        <div>
            <input :placeholder="placeholder" class="input" type="text" v-model="word" @keydown.enter="enter">
            <a :href="url + word" target="_blank" @click="search">搜索</a>
        </div>
    </div>
</template>

<script>
import navigation from "../navigation.json"

export default {
    name: "SearchBar",
    data() {
        return {
            url: "https://www.google.com/search?q=",
            word: "",
            dom: [this.$refs.google, this.$refs.bing, this.$refs.baidu, this.$refs.zhihu, this.$refs.wikipedia],
            urls: [
                'https://www.google.com/search?q=',
                'https://www.bing.com/search?q=',
                'https://www.baidu.com/s?wd=',
                'https://www.zhihu.com/search?type=content&q=',
                'https://zh.wikipedia.org/wiki/',
                'https://www.google.com/search?q='
            ],
            navigation,
            introduces: [
                "Google搜索",
                "百度一下",
                "Bing搜索",
                "知乎",
                "维基百科",
                "手气不错"
            ],
            placeholder: ""
        }
    },
    methods: {
        changeSearch(url, index) {
            if (url !== null) {
                this.url = url;
            }
            this.dom.forEach(item => item.style.backgroundColor = "white");
            this.dom[index].style.backgroundColor = "rgba(197, 185, 185, 0.25)";
            localStorage.index = index;
            this.placeholder = this.introduces[index];
        },
        enter() {
            if (localStorage.index !== "5") {
                window.open(this.url + this.word, '_blank')
            } else {
                this.lucky();
            }
        },
        search(e) {
            if (localStorage.index === "5") {
                this.lucky();
                e.preventDefault();
            }
        },
        lucky() {
            let flag = true;
            for (let i = 0; i < this.navigation.navigation.length; i++) {
                let t = this.navigation.navigation[i];
                for (let j = 0; j < t.links.length; j++) {
                    let tt = t.links[j];
                    if ((tt.name.toLowerCase() === this.word.toLowerCase()) || (tt.anotherName !== null && tt.anotherName !== undefined && tt.anotherName.toLowerCase() === this.word.toLowerCase())) {
                        window.open(tt.url, '_blank')
                        flag = false;
                    }
                }
            }
            if (flag) {
                window.open(this.url + this.word, '_blank')
            }
        }
    },
    mounted() {
        this.dom = [this.$refs.google, this.$refs.bing, this.$refs.baidu, this.$refs.zhihu, this.$refs.wikipedia, this.$refs.lucky];
        let index = localStorage.index;
        if (index === undefined || index === null) {
            this.dom[0].style.backgroundColor = "rgba(197, 185, 185, 0.25)";
            this.url = this.urls[0];
            this.placeholder = this.introduces[0];
        } else {
            this.dom[Number.parseInt(index)].style.backgroundColor = "rgba(197, 185, 185, 0.25)";
            this.url = this.urls[Number.parseInt(index)];
            this.placeholder = this.introduces[Number.parseInt(index)];
        }
    }
}
</script>

<style scoped>
.item {
    padding: 5px 15px;
    margin-left: 10px;
    display: inline-block;
    /*background-color: gray;*/
    border-radius: 15px;
    cursor: pointer;
}

.item:hover {
    background-color: #C4C4C43F;
}

.item:active {
    background-color: #C5C5C58C;
}

a {
    background-color: #444444;
    color: white;
    display: inline-block;
    text-decoration: none;
    width: 100px;
    height: 48px;
    line-height: 48px;
    margin-left: 30px;
    padding: 0 15px;
    border-radius: 40px;

}

a:hover {
    background-color: #2a2a2a;
}

a:active {
    background-color: #232323;
}

.input {
    line-height: 26px;
    border: 0px;
    background-color: #f5f5f5;
    width: 500px;
    font-size: inherit;
    padding: 12px 20px;
    border-radius: 40px;
}

.select {
    width: 580px;
    /*background-color: rgba(255, 0, 0, 0.47);*/
    margin-left: 225px;
    margin-bottom: 20px;
}

.input {
    outline-style: none;
    border: 0px;
}

.input:link {
    border: 0 none;
    outline: none;
}

.input:visited {
    border: 0 none;
    outline: none;
}

.input:hover {
    border: 0 none;
    outline: none;

}

.input:active {
    border: 0 none;
    outline: none;
}
</style>