<template>
    <ul>
        <li>
            <span class="header">{{val.category}}</span>
        </li>
        <li v-for="(item, index) in val.links" :key="index">
            <a :href="item.url" target="_blank">{{ item.name }}</a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "Item",
    props: {
        val: {
            default: {

            }
        }
    }
}
</script>

<style scoped>
.header {
    color: #999999;
}
/*ul{*/
/*    background-color: red;*/
/*}*/
a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.70);
    /*background-color: red;*/
    padding-right: 15px;
    padding-left: 15px;
    height: 100%;
    display: inline-block;
    border-radius: 50px;
}

a:hover {
    background-color: #C4C4C43F;
}

a:active {
    background-color: #C5C5C58C;
}
*{
    padding: 0;
}
li {
    display: inline-block;
    width: 10.5%;
    /*width: 140px;*/
    white-space: nowrap;
    height: 32px;
    line-height: 32px;
    padding-bottom: 10px;
    padding-top: 10px;
}
ul{
    margin-right: 40px;
}
</style>